.nav-icon {
    display: none;
}

.itens-menu {
      display: flex;
      color: #f4f4f4;
      text-transform: uppercase;
      font-weight: 700;
    }

      .menu {
        list-style: none;
        margin-left: 60px;          
        cursor: pointer;
      }

        .menu:hover {
            color: #F9CA0E;
            transition: 0.1s;
        }

.fa-bars {
    cursor: pointer;
    color: #F9CA0E;
}

.fa-times {
    cursor: pointer;
    color: #F9CA0E;
}

@media screen and (max-width: 900px) {
    .fa-bars {
        color: #F9CA0E;
    }
    
    .fa-times {
        color: #F9CA0E;
    }

    .itens-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 700px;
        border-top: 2px solid #f4f4f4;
        border-bottom: 2px solid #f4f4f4;
        position: absolute;
        top: 80px;
        left: -110%;
        opacity: 0,
    }

        .itens-menu.active {
            background-color: #5C5C58;
            left: 0px;
            opacity: 1;
            transition: all .5s ease;
            z-index: 1;
        }

            .nav-icon {
                display: block;
                position: absolute;
                top: 80px;
                right: 5%;
                transform: translate(-100%, 60%);
                font-size: 1.6rem;
            }

            .menu {
                margin-left: 0; 
                text-align: center;
                margin-top: 30px; 
                font-size: 20px;
                margin-bottom: 15px;     
            }

}