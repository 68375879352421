    .container_equipe {
        box-sizing: border-box;
    }

        .swiper_container_equipe {
            height: 650px;
        }

        .slide_item_equipe {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

            .imagem_equipe {
                height: 500px;
            }

            .textos-slide {
                text-align: right;
                padding-right: 10%;
            }

                .nome_colaborador {
                    color: #f9ca0e;
                    font-weight: 1000;
                    margin-bottom: 5px;
                    font-size: 30px;
                    text-transform: uppercase;
                }

                .funcao {
                    font-weight: 700;
                    font-size: 20px;
                    margin-bottom: 10px;
                    color: #f4f4f4;
                }

                .sobre_funcao {
                    font-size: 18px;
                    line-height: 1.4;
                    color: #f4f4f4;
                    font-weight: 600;
                }

        .swiper_container_equipe .swiper-pagination { 
            position: absolute; 
        }

        .swiper_container_equipe .swiper-pagination > .swiper-pagination-bullet {
            background-color: #5C5C58;
            opacity: 0.5;
            color: #5C5C58;
            padding: 8px;
        }

        .swiper_container_equipe .swiper-pagination > .swiper-pagination-bullet-active {
            background-color: #5C5C58;
            opacity: 1;
            padding: 8px;
            }

        .swiper-button-prev::after, .swiper-button-next::after {
            color: white;
            display: none;
        }


@media (max-width: 600px) {
        .swiper_container_equipe {
            height: 650px;
        }

            .slide_item_equipe {
                display: flex;
                flex-direction: column;
                justify-content: start;
                align-items: center;
                margin-bottom: 30px;
            }

                .imagem_equipe {
                    margin-bottom: 30px;
                    height: auto;
                }

                .textos-slide {
                    text-align: left;
                    padding-left: 10%;
                    padding-right: 10%;
                }

                    .nome_colaborador {
                        font-size: 22px;
                    }

                    .funcao {
                        font-size: 20px;
                    }
}
