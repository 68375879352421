html {
  font-family: 'Inter', sans-serif;
  scroll-behavior: smooth;
}

body {
  margin: 0 auto;
}

body::-webkit-scrollbar {
  width: 10px;
  background: #f4f4f4;
} 

body::-webkit-scrollbar-thumb {   
  background-color: #5C5C58;
  border-radius: 7px;
}


