    .container_servicos_carrossel {
        box-sizing: border-box;
        margin-left: 10%;
        margin-bottom: 40px;
    }

        .slide-item-servicos {
            height: 500px;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            margin-top: 20px;
        }

            .slide-item-servicos:hover {
                top: -7px;
            }

            .slide-item-servicos:hover img {
                box-shadow: 2px 2px 5px black;
            }

            .slide-item-servicos:hover .cta_saiba_mais_servicos {
                background-color: #F9CA0E;
                color: #5C5C58;
            }

            .imagem_servicos {
            width: 100%;
            height: 50%;
            object-fit: cover;
            border-radius: 18px;
            }

            .nome_servico {
            padding-top: 10px;
            font-size: 20px;
            color: #F9CA0E;
            padding-bottom: 7px;
            font-weight: 700;
            }

            .desc_servico {
                line-height: 1.5;
                color: white;
            }

            .cta_saiba_mais_servicos {
                background-color: #f4f4f4;
                font-size: 14px;
                color: #5C5C58;
                height: 40px;
                width: 60%;
                border-radius: 20px;
                cursor: pointer;
                margin-top: 10px;
                padding: 2px;
                font-weight: 700;
                text-transform: uppercase;
            }

                .cta_saiba_mais_servicos:hover {
                    box-shadow: 2px 2px 5px black;
                }

        .swiper-container-servicos .swiper-pagination { 
                    position: absolute; 
                }
        
                .swiper-container-servicos .swiper-pagination > .swiper-pagination-bullet {
                    background-color: #f4f4f4;
                    opacity: 0.5;
                    color: #f4f4f4;
                    padding: 8px;
                }
        
                .swiper-container-servicos .swiper-pagination > .swiper-pagination-bullet-active {
                    background-color: #f4f4f4;
                    opacity: 1;
                    padding: 8px;
                    }
        
                .swiper-container-servicos .swiper-button-prev::after, .swiper-button-next::after {
                    color: white;
                    display: none;
                }

@media (max-width: 600px) {
    .container_servicos_carrossel {
        margin-left: 7%;
        margin-bottom: 10px;
    }

            .cta_saiba_mais_servicos {
                width: 80%;
            }

                .swiper-container-servicos .swiper-pagination {
                    margin-bottom: 20px;
                }
                
                    .swiper-container-servicos .swiper-pagination > .swiper-pagination-bullet {
                        padding: 6px;
                    }
            
                    .swiper-container-servicos .swiper-pagination > .swiper-pagination-bullet-active {
                        padding: 6px;
                    }
}