.container_portfolio {
    box-sizing: border-box;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

    .swiper-container-portfolio {
        width: 400px;
    }

        .slide-item-portfolio {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 18px;
        }

        .swiper-container-portfolio .swiper-pagination > .swiper-pagination-bullet {
            background-color: #5C5C58;
            opacity: 0.5;
            color: #5C5C58;
        }

        .swiper-container-portfolio .swiper-pagination > .swiper-pagination-bullet-active {
            background-color: #5C5C58;
            opacity: 1;
        }

    .baixar_portfolio {
        background-color: #5C5C58;
        color: #F9CA0E;
        font-weight: 700;
        padding: 10px 90px;
        margin-top: 30px;
        font-size: 18px;
        font-weight: 700;
        text-transform: uppercase;
        border-radius: 18px;
        cursor: pointer;
        box-shadow: 2px 2px 5px black;
    }

        .baixar_portfolio:hover {
            box-shadow: 2px 2px 10px black;
        }

@media (max-width: 600px) {
    .container_portfolio {
        box-sizing: border-box;
        margin-top: 80px;
        padding-bottom: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

        .swiper-container-portfolio {
            width: 280px;
        }

.baixar_portfolio {
    padding: 7px 22px;
    margin-top: 50px;
    font-size: 20px;
    }

}