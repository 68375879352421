    .container_secao_equipe {
        background: url(./../../public/imagens/background_secao_equipe.webp) right center;
    }

        .titulo_secao_equipe {
            padding-top: 80px;
            padding-left: 150px;
            font-size: 40px;
            text-align: left;
            text-transform: uppercase;
            color: #f4f4f4;
            font-weight: 800;
    }

@media (max-width: 600px) {
    .container_secao_equipe {
        background: #5C5C58;
    }  

        .titulo_secao_equipe {
            padding-left: 0;
            font-size: 30px;
            text-align: center;
            margin-bottom: 30px;
        }
    }
        