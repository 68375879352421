.area_formulario {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

    iframe {
        width: 100%;
        height: 450px;
    }

@media (max-width: 600px) {
    iframe {
        height: 550px;
    }
}